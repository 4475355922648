<template>
    <div class="header_content flexjc">
        <div class="header_box flexjc">
            <img src="../assets/img/logo2.png" alt="" class="">
            <div class="right flex ">
                <div v-for="(item, index) in menu" :key="index" class="item cur relative"
                    @mouseenter="mouseenterLocal(true, 1, index)">
                    <span :class="[{ 'active': index == active_menu }]" style="color:#fff;font-size: 16px ;"
                        @click="go(item, index)"> {{ $t(item.name)
                        }}</span>
                    <div class="white_box white_box_com textAC" v-if="index == 1 && isshowinfo"
                        @mouseleave="mouseenterLocal(false, 1, index)">
                        <div v-for="(item, index) in info" :key="index" class="info_item" @click="go1(item, index)">{{
                        item.name
                        }}</div>
                    </div>
                </div>
                <div class="relative textAC flex" @mouseenter="mouseenterLocal(true, 2)">
                    <div class="local_item"></div>
                    <div class="cur local nowrap" style="color:#fff">{{ localzh.name }}</div>
                    <div class="white_box white_box1 white_box_lang" v-if="isshowLocal" @mouseleave="mouseenterLocal(false, 2)">
                        <div v-for="(item, index) in localArray" :key="index" class="info_item lang_item textAC cur"
                            @click.stop="changeLocal(index, item)">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { reactive, toRefs } from 'vue';
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import _store from '../store'
import { useRouter } from 'vue-router';
export default {
    props: ['active_menu'],
    setup(props) {
        console.log(props)
        const { locale } = useI18n()
        const store = useStore() || _store;
        const router = useRouter()
        const changeLocal = (index, item) => {
            data.localzh = item;
            // data.local = item.id
            // sessionStorage.setItem('local', item.id)
            store.commit('changeLocale', item.id)
            // data.localzh.name = sessionStorage.getItem('local') == 'en' ? '英文' : sessionStorage.getItem('local') == 'jp' ? "日文" : '中文'
            locale.value = item.id
            // data.num1 = false
            // router.push({
            //   path: '/home'
            // })
            // data.num = 0
            // sessionStorage.setItem("current", 0)
        }
        const go = (item, index) => {
            if (index != 1) {
                // data.active_menu = index
                router.push({
                    path: item.path
                })
            }
        }
        const go1 = (item) => {
            // data.active_menu_com = index
            // data.active_menu = 1
            router.push({
                path: item.path
            })
        }
        const mouseenterLocal = (val, index, index1) => {
            if (index == 2) {
                data.isshowLocal = val
            } else if (index1 == 1) {
                data.isshowinfo = val
            }
            if (index1 != 1) {
                data.isshowinfo = false
            }

        }
        const data = reactive({
            active_menu_com: 0,//当前公司信息
            // active_menu: 0,//当前目录
            localzh: { name: "中文" },
            localArray: [{ name: '中文', id: 'zh' }, { name: 'English', id: 'en' }, { name: '日本語', id: 'jp' }],
            menu: [
                { name: "首页", path: '/home' },
                { name: "公司信息", },
                { name: "公司业务", path: '/company' },
                { name: "新闻资讯", path: '/news' },
                { name: "合作伙伴", path: '/cooperation' },
                { name: "联系我们", path: "/content_us" },
            ],
            info: [
                { name: "公司介绍", path: '/about_us' },
                { name: "官员介绍", path: '/officials' },
            ],
            isshowLocal: false, //显示语言
            isshowinfo: false,//显示公司信息
        })
        return {
            go,
            go1,
            changeLocal,
            mouseenterLocal,
            ...toRefs(data)
        }
    },
    mounted(){
        console.log('actice',this.active_menu);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .local:hover {
    color: #578ECF;
} */
.localCli {}

.white_box1 {
    width: 100px;
    right: -40px;
    top: 66px !important;
}


.info_item:hover {
    background-color: #578ECF;
    color: #fff !important;
}


.active {
    /* color: #578ECF !important; */
}

.active::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: #FFFFFF;
    bottom: -20px;

}

.white_box {
    top: 58px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px rgba(20, 28, 41, 0.1);
    opacity: 1;
    line-height: 60px;
    color: #707070;
}

.white_box:nth-of-type(1) {
    left: -80%;
    width: 180px;
}

.local_item {
    height: 32px;
    width: 1px;
    background: #fff;
    opacity: 0.2;
    margin: 0 40px;
}

.right {
    flex: 1;
    max-width: 80%;
    margin-left: 300px;
    justify-content: flex-end;
}

.item {
    margin-left: 78px;
    white-space: nowrap;
}

.item:hover {
    color: #578ECF;
}

.header_content {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #1F4A92;
    z-index: 9999999;
}

.header_box {
    width: 900px;
    margin: 0 auto;
    padding: 24px 0px;

}

@media screen and (max-width:1000px) {
    .header_box {
        width: 100%;
        margin: 0;
        /* margin-left: 10px; */
    }

    .header_box>img {
        border: 1px solied red;
        width: 40px;
    }

    .right {
        /* margin-left: 80px !important; */
        justify-content: center;
        margin: 0;
    }

    .item {
        margin-left: 0px;
        padding: 0 2px;
    }
.item>span{
    font-size: 8px !important;
}
    .header_content {
        width: 100vw;
        height: 60px;
    }
    .local_item{
        height: 3vw;
        margin: 0 4px;
    }
    .nowrap{
        font-size: 6px !important;
        line-height: 6px !important;
    }
  .white_box_com{
    width: 40px !important;
    font-size: 8px;
    line-height: 20px;
    height: 40px;
    top: 40px;
    left: 0px !important;
  }
.white_box_lang{
    width: 50px;
    font-size: 8px;
    line-height: 20px;
    top: 20px !important;
}
}
</style>
