<template>
    <Header active_menu="4"/>
    <div class="flexjc">
        <div class=" content">
        <div class="textAl"><span>{{ $t('您的位置：首页') }}</span><span class="dayu">&gt;</span><span class="hezu">{{ $t('合作伙伴') }}</span></div>
        <img src="./../assets/img/hezuo2.png" alt="" class="" style="margin:80px auto 200px">
        <div class="COPY">COPYRIGHT(@)2022mo005_19560-{{$t('企业集团类官网')}}</div>
    </div>
    </div>


</template>   

<script>
import Header from '../components/Header2.vue'
import { reactive, toRefs } from "vue";


export default {
    name: 'Home',

    setup() {
        let active = reactive({

        })

        return {
            ...toRefs(active),
        };
    },
    components: {
        Header,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>


span {
    font-size: 14px;
}

.dayu {
    font-family: "宋体";

    color: #666666;
    margin: 0 10px;
}

.hezu {
    color: #5790CB;
}

.content {
    max-width: 1240px;
    margin: 0 auto;
    padding: 148px 0;
    box-sizing: border-box;
    flex-direction: column;
}
@media screen and (max-width:1000px) {
    .textAl>span{
        font-size: 6px !important;
    }
    .content{
        padding-top: 80px;
    }
    .content>img{
        margin: 10px !important;
        width:100%;
    }
    .COPY{
        margin-top: 450px;
        font-size: 8px;
    }
}
</style>
